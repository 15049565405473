export type UnitOfMeasurePhrase = {
    [languageCode: string]: string;
};

export type UnitOfMeasure = {
    display: boolean;
    term: string;
    phrase: UnitOfMeasurePhrase;
};

// GENERAL NOTES
// In the future we may need to store this in the database on a per-supplier basis
// For now we are just keeping this a static hash in the frontend code
// We'll see how long before this bites us :)

// PHRASE DETAILS
// The phrase has two attributes that are overridden in display
// {num} is replaced by the qty of unit of measure
// The `/s` pluralization is shown or hidden depending on the qty
// Anything after the / is considered the pluralization

const dictionary: Record<string, UnitOfMeasure> = {
    BD: {
        display: true,
        term: 'Bundle',
        phrase: { en: 'Bundle of {num}' },
    },
    BG: {
        display: true,
        term: 'Bag',
        phrase: { en: 'Bag of {num}' },
    },
    BK: {
        display: true,
        term: 'Bank',
        phrase: { en: '{num} bank/s' },
    },
    BR: {
        display: true,
        term: 'Barrel',
        phrase: { en: '{num} barrel/s' },
    },
    BT: {
        display: true,
        term: 'Bottle',
        phrase: { en: '{num} bottle/s' },
    },
    BX: {
        display: true,
        term: 'Box',
        phrase: { en: 'Box of {num}' },
    },
    C: {
        display: true,
        term: 'Hundred (100)',
        phrase: { en: '{num} each' },
    },
    CA: {
        display: true,
        term: 'Case',
        phrase: { en: 'Case of {num}' },
    },
    CF: {
        display: true,
        term: 'Cubic foot',
        phrase: { en: '{num} cubic feet' },
    },
    CM: {
        display: true,
        term: 'Centimeter',
        phrase: { en: '{num} centimeter/s' },
    },
    CN: {
        display: true,
        term: 'Can',
        phrase: { en: '{num} cans' },
    },
    CY: {
        display: true,
        term: 'Cylinder',
        phrase: { en: '{num} cylinder/s' },
    },
    DR: {
        display: true,
        term: 'Drum',
        phrase: { en: '{num} drum/s' },
    },
    DZ: {
        display: true,
        term: 'Dozen',
        phrase: { en: '{num} dozen' },
    },
    EA: {
        display: false,
        term: 'Each',
        phrase: { en: '' },
    },
    FT: {
        display: true,
        term: 'Foot',
        phrase: { en: '{num} feet' },
    },
    GA: {
        display: true,
        term: 'Gallon',
        phrase: { en: '{num} gallon/s' },
    },
    GR: {
        display: true,
        term: 'Gross',
        phrase: { en: '{num} gross' },
    },
    IN: {
        display: true,
        term: 'Inch',
        phrase: { en: '{num} inch/es' },
    },
    IP: {
        display: true,
        term: 'Inner pack',
        phrase: { en: '{num} inner pack/s' },
    },
    KG: {
        display: true,
        term: 'Kilogram',
        phrase: { en: '{num} kilogram/s' },
    },
    LB: {
        display: true,
        term: 'Pound',
        phrase: { en: '{num} pound/s' },
    },
    LF: {
        display: true,
        term: 'Pipe lift',
        phrase: { en: '{num} pipe lift/s' },
    },
    LT: {
        display: true,
        term: 'Lot',
        phrase: { en: '{num} lot/s' },
    },
    M: {
        display: true,
        term: 'Thousand (1,000)',
        phrase: { en: '{num} each' },
    },
    MT: {
        display: true,
        term: 'Meter',
        phrase: { en: '{num} meter/s' },
    },
    OZ: {
        display: true,
        term: 'Ounce',
        phrase: { en: '{num} ounce/s' },
    },
    PC: {
        display: true,
        term: 'Piece',
        phrase: { en: '{num} piece/s' },
    },
    PI: {
        display: true,
        term: 'Pint',
        phrase: { en: '{num} pint/s' },
    },
    PK: {
        display: true,
        term: 'Pack',
        phrase: { en: 'Pack of {num}' },
    },
    PL: {
        display: true,
        term: 'Pail',
        phrase: { en: '{num} pail/s' },
    },
    PR: {
        display: true,
        term: 'Pair',
        phrase: { en: '{num} pair/s' },
    },
    PT: {
        display: true,
        term: 'Pallet',
        phrase: { en: '{num} pallet/s' },
    },
    QT: {
        display: true,
        term: 'Quart',
        phrase: { en: '{num} quart/s' },
    },
    RL: {
        display: true,
        term: 'Roll',
        phrase: { en: '{num} roll/s' },
    },
    SE: {
        display: true,
        term: 'Set',
        phrase: { en: '{num} set/s' },
    },
    SF: {
        display: true,
        term: 'Square foot',
        phrase: { en: '{num} square feet' },
    },
    ST: {
        display: true,
        term: 'Stick',
        phrase: { en: '{num} stick/s' },
    },
    SY: {
        display: true,
        term: 'Square yard',
        phrase: { en: '{num} square yard/s' },
    },
    TU: {
        display: true,
        term: 'Tube',
        phrase: { en: '{num} tube/s' },
    },
    YD: {
        display: true,
        term: 'yard',
        phrase: { en: '{num} yard/s' },
    },
};

const getTermFromCode = (unitCode: string, forceDisplay?: boolean): string => {
    const unit = dictionary[unitCode];
    if (!unit || (!unit.display && !forceDisplay)) return null;
    return unit.term;
};

const shouldShowUOM = (unitCode: string): boolean => {
    const unit = dictionary[unitCode];
    return !(!unit || !unit.display);
};

export { dictionary, getTermFromCode, shouldShowUOM };
